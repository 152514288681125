import axios from 'axios';
import {getHeaders} from "./common";

export async function cookiePartnerCall(data) {
    
    try {

        let resp = await axios.post('/api/partnerconfig',data,{
            headers: getHeaders(),
        });
        // if(!resp.data.status){
        //     throw resp.data;
        // }

        return resp.data
    } catch (err) {
        return false;

    }
    
}

export async function getAffilateDetails(params) {
    try {
        
        let body = {key:params}

        let resp = await axios.post('/api/ampdecrypt',body,{
            headers: getHeaders(),
        });
        
        // if(!resp.data.status){
        //     throw resp.data;
        // }
        
        return resp.data
    } catch (err) {
        return false;

    }

}

export function getPriorCookie() {
    try {

        var cookieValue = document.cookie.split('; ').filter(row => row.startsWith("aff_id_"));
  
        var allCookies = [];
      
        for(var cookie of cookieValue) {
            var value = cookie.split('=')[1];
            allCookies.push(JSON.parse(value))
        }
      
        var priorityCookie;
      
        if(allCookies.length > 0){
      
        var min = allCookies[0].priority;
      
        var minIndex = 0;
        
        for(var i=1; i<allCookies.length; i++){
      
            if (allCookies[i].priority < min) {
            min = allCookies[i].priority;
            minIndex = i
            }
        }
      
        priorityCookie = allCookies[minIndex];
      
        }
        return JSON.stringify(priorityCookie);
    }catch(err){
        console.log(err);
        return err;
    }
}