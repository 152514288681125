function getCookieValue(cname, ckey) {
  if (typeof ($.cookie(cname)) !== 'undefined') {
    var valuearray = unescape($.cookie(cname)).split('&');
    for (var i = 0; i < valuearray.length; i++) {
      if (valuearray[i].indexOf(ckey) >= 0) {
        var keyval = valuearray[i].split('=');
        if (keyval.length > 1) {
          return keyval[1];
        }
      }
    }
  }

  return "";
}

function  getpageInfo(pageName, subCategory1, milesCategory) {
  return {
    category: "",
    pageName: pageName,
    subCategory1: subCategory1,
    subCategory2: "",
    subCategory3: "",
    milesCategory: milesCategory,
    property: "Interbook",
    pageSource: (window.navigator.userAgent === "IM-Mobile-App") ? (page == 'SRP' ? 'imapp' : 'imweb') : (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent)) ? 'immob' : 'imweb',
    platform: (window.navigator.userAgent === "IM-Mobile-App") ? 'imweb' : (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent)) ? 'immob' : 'imweb',
    isWebView: (window.navigator.userAgent === "IM-Mobile-App") ? 'Y' : 'N'
  }
}

export const Adobe = {
  // Click Event
  clickEvent: function (eventName, pageName, subCategory1, subCategory2, subCategory3, milesCategory, userInfo) {
    try {
      let pageInfo = getpageInfo(pageName, subCategory1, milesCategory)
      window.digitalData = {
        eventInfo: {
          eventName: eventName,
          eventType: 'click',
        },
        pageInfo: pageInfo,
        userInfo: userInfo,
      }
      // console.log("Click Event", window.digitalData)
      _satellite.track('eventTracking');
    } catch (e) {
      console.log("adobe err:", e)
    }
  },

  //offers and deals click
  offerClickEvent: function (pageName, subCategory1, milesCategory, userInfo, offerInfo) {
    try {
      let pageInfo = getpageInfo(pageName, subCategory1, milesCategory)
      window.digitalData = {
        pageInfo: pageInfo,
        userInfo: userInfo,
        eventInfo: {
          eventName: 'offers and deals click',
        },
        offerInfo: {
          ...offerInfo,
          brickType: 'Hotels',

        },
        brickInfo: {
          partnerName: "",
          partnerCode: "",
        },
        promoCode: "",
      }
      _satellite.track('offerClicked');
      // console.log("offer click",window.digitalData);
    } catch (e) {
      console.log("adobe err:", e)
    }
  },
  
  // Error Event 
  errorTracking: function (pageName, subCategory1, milesCategory, userInfo, errorDescription, errorCode) {
    try {
      let pageInfo = getpageInfo(pageName, subCategory1, milesCategory)
      window.digitalData = {
        pageInfo: pageInfo,
        userInfo: userInfo,
        eventInfo: {
          eventName: "error",
        },
        errorInfo: {
          errorDescription: errorDescription,
          errorCode: errorCode
        },
      }
      // console.log("Error Event", window.digitalData);
      _satellite.track('errorTracking');
    } catch (e) {
      console.log("adobe err:", e)
    }
  },

  
  InterbookSearchClick: function (pageName, subCategory1, milesCategory, userInfo, hotelId, searchInfo, sortValue = "", filterUsed = "") {
    try {
      let pageInfo = getpageInfo(pageName, subCategory1, milesCategory)
      window.digitalData = {
        eventInfo: {
          eventName: "search click",
        },
        pageInfo: pageInfo,
        userInfo: userInfo,
        searchInfo: searchInfo,
        propertyInfo: {
          hotelId: hotelId || ""
        },
        sortValue: sortValue,
        filterUsed: filterUsed,
      }
      _satellite.track('InterbookSearchClick');
    }
    catch (e) {
      console.log("adobe err:", e)
    }
  },
  // property click event 
  propertyClick: function (pageName, subCategory1, milesCategory, userInfo, filterUsed, searchInfo, sortValue, hotelId, paymentType) {
    try {
      let pageInfo = getpageInfo(pageName, subCategory1, milesCategory)
      window.digitalData = {
        eventInfo: {
          eventName: "property click",
        },
        pageInfo: pageInfo,
        userInfo: userInfo,
        filterUsed: filterUsed,
        searchInfo: searchInfo,
        sortValue: sortValue,
        propertyInfo: {
          hotelId: hotelId
        },
        paymentType: paymentType,

      }
      _satellite.track('interbookSearchResultClick');
    } catch (e) {
      console.log("adobe err:", e)
    }
  },
  interbookSearchResultClick: function (req, userInfo) {
    try {
      window.digitalData = {
        eventInfo: {
          eventName: "property click",

        },
        pageInfo: {
          category: "",
          pageName: req.pageInfo.pageName,
          subCategory1: req.pageInfo.subCategory1,
          subCategory2: req.pageInfo.subCategory2,
          subCategory3: req.pageInfo.subCategory3,
          milesCategory: req.pageInfo.milesCategory,
          property: "Interbook",
          pageSource: (window.navigator.userAgent === "IM-Mobile-App") ? (page == 'SRP' ? 'imapp' : 'imweb') : (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent)) ? 'immob' : 'imweb',
          platform: (window.navigator.userAgent === "IM-Mobile-App") ? 'imweb' : (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent)) ? 'immob' : 'imweb',
          isWebView: (window.navigator.userAgent === "IM-Mobile-App") ? 'Y' : 'N'
        },
        userInfo: userInfo,
        searchInfo: {
          propertyName: req.searchInfo.propertyName,
          searchType: req.searchInfo.searchType,
          sectionClicked: req.searchInfo.sectionClicked,
        },
        filterUsed: req.filterUsed,
        propertyInfo: {
          hotelId: req.propertyInfo.hotelId,
        },
        paymentType: req.paymentType,
      }
      _satellite.track("interbookSearchResultClick")
    } catch (e) {
      console.log("adobe err:", e)
    }

  },
  // book room
  bookroom: function (pageName, subCategory1, milesCategory, userInfo, searchInfo, propertyInfo, paymentType) {
    try {
      let pageInfo = getpageInfo(pageName, subCategory1, milesCategory)
      window.digitalData = {
        eventInfo: {
          eventName: "book room",
        },
        pageInfo: pageInfo,
        userInfo: userInfo,
        searchInfo: searchInfo,
        propertyInfo: propertyInfo,
        paymentType: paymentType
      }
      _satellite.track('interbookBookRoom')
    } catch (e) {
      console.log("adobe err:", e)
    }
  },

  payNow: function (pageName, subCategory1, milesCategory, userInfo, searchInfo, propertyInfo, paymentType) {
    try {
      let pageInfo = getpageInfo(pageName, subCategory1, milesCategory)
      window.digitalData = {
        eventInfo: {
          eventName: "Pay Now",
        },
        pageInfo: pageInfo,
        userInfo: userInfo,
        searchInfo: searchInfo,
        propertyInfo: propertyInfo,
        paymentType: paymentType,
      }
      _satellite.track('interbookPayNow');
    }
    catch (e) {
      console.log("adobe err:", e)
    }
  },

  pageLoad: function (pageName, subCategory1, milesCategory, userInfo) {
    try {
      // console.log("page load^^^^^^^");
      let pageInfo = getpageInfo(pageName, subCategory1, milesCategory)
      window.digitalData = {
        pageInfo: pageInfo,
        userInfo: userInfo
      }
      // console.log("loading**************", digitalData)
      _satellite.track('page load');
    } catch (err) {
      console.log(err);
    }
  },

  srpPageLoad: function (pageName, subCategory1, milesCategory, userInfo, sortValue, filterUsed, noOfResult) {
    try {
      let pageInfo = getpageInfo(pageName, subCategory1, milesCategory)
      window.digitalData = {
        pageInfo: pageInfo,
        userInfo: userInfo,
        sortValue: sortValue,
        filterUsed: filterUsed,
        noOfResults: noOfResult || "",
      }

      _satellite.track('page load');
    } catch (err) {
      console.log(err);
    }
  },

  bookingConfirmation: function (pageName, subCategory1, milesCategory, userInfo, propertyInfo, offer, searchInfo, errorInfo, bookingStatus, paymentType, totalAmount, milesEarned, milesRedeemed, transactionID, promoCode) {
    try {
      let pageInfo = getpageInfo(pageName, subCategory1, milesCategory)
      window.digitalData = {
        eventInfo: {
          eventName: "Booking Confirmation",
        },
        pageInfo: pageInfo,
        userInfo: userInfo,
        bookingStatus: bookingStatus,
        searchInfo: searchInfo,
        propertyInfo: propertyInfo,
        paymentType: paymentType,
        totalAmount: totalAmount,
        milesEarned: milesEarned,
        milesRedeemed: milesRedeemed,
        offer: offer,
        promoCode: promoCode,
        transactionID: transactionID,
        errorInfo: errorInfo
      }
      _satellite.track('interbookBookingConfirmation')
    } catch (e) {

      console.log("adobe err:", e)
    }
  }

}

