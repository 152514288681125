export var utmUrlParam = function(url){
    var utmUrl = ''

    let paramString = url.split('?')[1];

    // let params_arr = paramString.split('&');

    // for (let i = 0; i < params_arr.length; i++) {

    //     let pair = params_arr[i].split('=');
    //     var utm_source = (pair[0] == 'utm_source') ? pair[1] : ''
    //     var utm_campaign = (pair[0] == 'utm_campaign') ? pair[1] : ''
    //     var utm_medium = (pair[0] == 'utm_medium') ? pair[1] : ''
    //     var affiliate_partner = (pair[0] == 'affiliate_partner') ? pair[1] : ''
    //     var marketing_channel = (pair[0] == 'marketing_channel') ? pair[1] : ''

    //     console.log("Key is:" + pair[0]);
    //     console.log("Value is:" + pair[1]); k
    // }

    let urlParams = new URLSearchParams(paramString)

    let utm_campaign = urlParams.get('utm_campaign')
    let utm_source = urlParams.get('utm_source')
    let utm_medium = urlParams.get('utm_medium')
    let utm_content = urlParams.get('utm_content')

    let affiliate_partner = urlParams.get('affiliate_partner')
    let partnercode = urlParams.get('partnercode')
    let marketing_channel = urlParams.get('utm_marketing_channel')
    utmUrl = (utm_campaign || utm_source || utm_medium || utm_content || affiliate_partner || partnercode || marketing_channel) ? (utm_campaign ? '&utm_campaign='+utm_campaign : '') + (utm_source ? '&utm_source='+utm_source : '') +
                (utm_medium ? '&utm_medium='+utm_medium : '') + (utm_content ? '&utm_content='+utm_content : '') + (affiliate_partner ? '&affiliate_partner='+affiliate_partner : '') +  (partnercode ? '&partnercode='+partnercode : '')+
                (marketing_channel ? '&utm_marketing_channel='+marketing_channel : '') : '';
    return utmUrl;

}
