import config from '../config/config_frontend.json';
import { getPriorCookie } from '../actions/cookiePriority';
import { loginToggleModal } from "../services/newAuth"
import config_backend from '../config/config_backend.json';

export function getHeaders() {
  return {
    "Content-Type": "application/json",
    "auth_token": (localStorage.getItem('access_token') !== null && localStorage.getItem('access_token') !== undefined) ? localStorage.getItem('access_token') : '',
    "session_id": (localStorage.getItem('session_id') !== null && localStorage.getItem('session_id') !== undefined) ? localStorage.getItem('session_id') : '',
    "affilate_details": getPriorCookie() ? getPriorCookie() : '',
    "comsDetails": (localStorage.getItem('comsDetails') !== null && localStorage.getItem('comsDetails') !== undefined) ? localStorage.getItem('comsDetails') : ''
  }
}


export function removeAffilateCookie() {

  let utmPropertyObj = {};

  let utm_URL = new URLSearchParams(window.location.href);

  for (var value of utm_URL.keys()) {
    utmPropertyObj[value] = utm_URL.get(value);    //getting all params from the current url and adding in utmPropertyObj
  }
  //   console.log('utmParameterObject', utmPropertyObj['utm_content'] ===  '' || null || 'undefined' );
  //   console.log('utmParameterObject', utmPropertyObj['utm_medium'] === ( '' || null || undefined ));
  //   console.log('utmParameterObject', utmPropertyObj['utm_source'] !== ( '' || 'meta' || null || undefined ));
  //   console.log('utmParameterObject', utmPropertyObj['utm_campaign'] === ( '' || null || undefined ));
  //   console.log('utmParameterObject', utmPropertyObj['utm_marketing_channel'] === ( '' || null || undefined ));
  //   working code
  if (utmPropertyObj['utm_medium'] && utmPropertyObj['utm_medium'] !== 'meta'
    && utmPropertyObj['utm_medium'].length !== 0) {
    // console.log('utmParameterObject', true);
    let cookieValue = document.cookie.split('; ') //getting all affiliate cookies
      .filter(row => row.startsWith("aff_id_"));

    if (cookieValue.length > 0) {

      cookieValue.forEach(cookie => {

        let cookieName = cookie.substring(0, cookie.indexOf('=')); //getting all cookie names

        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`; //expiring cookies

      });

    }
  }
}

export function checkSupplierForPanDetails(resp) {
  if (resp.data.address.country_code !== "India" && resp.data.rooms.supplier_code === '10148') {
    return true;
  }

  return false;
}

export function loadRazorPay() {
  const script = document.createElement("script");
  script.src = config.razorpay_script;
  script.async = true;
  document.body.appendChild(script);
}
export function loadOtp() {
  const script = document.createElement("script");
  script.src = config.redemption_otp;
  script.async = true;
  document.body.appendChild(script);
}



export function callLoginPopup() {
  if (config_backend.isKeyCloakOn) {
    loginToggleModal()
  } else {
    window.loginToggleModal();
  }
  // Adobe start
  try {
    window.digitalData['eventInfo'] = {
      eventName: 'LOG IN',
      eventType: 'click'
    }
  } catch (e) {
    console.log("adobe Error:", e)
  }
  //    Adobe end
}
export function setCookie(cname, cvalue, exdays) {
  cvalue = cookideData(cvalue)
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 3600 * 1000));
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var cookies of ca) {
    var cookie = cookies

    while (cookie.charAt(0) == ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) == 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return "";
}

function cookideData(data) {
  let storeData = {
    receiver_first_name: data.receiver_first_name,
    receiver_last_name: data.receiver_last_name,
    receiver_country_code: data.receiver_country_code,
    receiver_email: data.receiver_email,
    receiver_mobile: data.receiver_mobile,
    receiver_title: data.title,
    receiver_im_number: data.receiver_im_number,
    receiver_country: data.receiver_country,
    denomination: data.denomination_amount
  }
  return JSON.stringify(storeData);
}



