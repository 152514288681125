import dynamic from 'next/dynamic'
import { ThemeProvider, InterMilesTheme } from '@jetprivilege/web/dist/theme';
import { ModalPopup } from '@jetprivilege/web/dist/modal-popup';
import LazyLoad from 'react-lazyload';
import { useState, useEffect } from 'react'
import config from '../../config/config_frontend.json'
import { removeAffilateCookie } from "../../actions/common";
import * as css from './Homepage_desktop.module.css'
import { HdpRedirect, addAdobeScript, setInitialValue, handlePageReload } from '../../Common_JS/homePage/homePage';
import { AdobePageLoad } from "../../Common_JS/homePage/homePageAdobe";

const SearchWidget = dynamic(() => import('../../components/SearchWidget/SearchWidget'))
const Advertising = dynamic(() => import('../../components/Advertising/Advertising'))
// const Recommendation = dynamic(() => import('../../components/Recommendation/recommendation'))
// const SpecialDeals = dynamic(() => import('../../components/SpecialDeals/specialdeals'))
const Discovery = dynamic(() => import('../../components/HomePage-Desktop/discovery'))
const Apartments = dynamic(() => import('../../components/HomePage-Desktop/apartments'))
const Destinations = dynamic(() => import('../../components/HomePage-Desktop/destinations'))
const HomeFaq = dynamic(() => import('../../components/FAQs/homepage_faq'))
// const HomeSkeleton = dynamic(() => import('../../components/Closet/homepage_skeleton'))
const SkeletonDesktop = dynamic(() => import('../../components/Skeletons/SkeletonDesktop'))
const OffersComponent = dynamic(() => import('../../components/OffersComponent/desktop'))
const CommonHotelRecommendationDesktop = dynamic(() => import('../../components/CommonHotelRecommendation/CommonHotelRecommendationDesktop'))
const Discovery_New = dynamic(() => import('../../components/HomePage-Desktop/discovery_new'))

let crossSale = {};
let page;
export default function HomePage(props) {

    const [homepageData, sethomepageData] = useState(props.homepageData)
    const [sectionTitles, setSectionTitles] = useState(props.titles)
    const [searchError, setSearchError] = useState(false);
    const [showSearchResultModal, setShowSearchResultModal] = useState(false);
    // const [onReady, setOnReady] = useState(false)
    const [mopType, setmopType] = useState(props.mop)

    useEffect(() => {
        // console.log("Special Deals V", props.specialDeals);
        removeAffilateCookie();

        setmopType(mopType);
        async function fetchData() {
            let data = await setInitialValue(props.homepageData, props.loggedIn_Info)
            if (!data) {
                setShowSearchResultModal(true);
                setSearchError(true);
                return
            }
            sethomepageData(data);
            setSectionTitles(data["titles"][0]);
            setTimeout(() => { AdobePageLoad() }, 2000);
        }
        // check for props.loggedIn_Info
        if (props.loggedIn_Info !== undefined) fetchData()
        addAdobeScript()

    }, [props.loggedIn_Info])

    // useEffect(() => {
    //     // Adobe start
    //     if (onReady) AdobePageLoad(props.loggedIn_Info)
    // }, [onReady])




    function HotelDetailRedirect(data, type) {
        HdpRedirect(data, type, props.loggedIn_Info, mopType)
    }

    function onCloseResponsiveModal() {
        setShowSearchResultModal(false)
    }
    return (
        <ThemeProvider theme={InterMilesTheme}>
            <div className={`${InterMilesTheme} parent`}>
                {searchError ?
                    (<div>
                        <ModalPopup
                            isOpen={showSearchResultModal}
                            contentLabel="Minimal Modal Example"
                            isMobileView={false}
                            closeButton={true}
                            className="jp-modalpopup-container jp-content-modal"
                            onRequestClose={onCloseResponsiveModal}
                            parentClassOverlay='errorpopup'
                        // parentClassModal='jp-modalpopup-container jp-content-modal'
                        >
                            <div className=''>
                                <div>
                                    {/* <a href="/" className="jp-cross-icon-container " role="button" id="closemodel" aria-label="Close">&nbsp;</a> */}
                                    <div className="fully-booked-content jp-p-t-36 jp-m-b-30 jp-text-center pagenotfoundcolum">
                                        <img src="/img/pagenotfound.svg" className="s-t-o-image jp-m-t-24 jp-m-b-30" />
                                        <h1 className="jp-session-timeout-heading jp-m-b-30 jp-color-2a3c4d">Oh No!</h1>
                                        <p> Something went wrong.</p>
                                    </div>
                                    <div className="fully-booked-btns jp-m-r--20 jp-m-l--20 jp-m-b--4 jp-b-t-ddd row" style={{ border: "none" }}>
                                        <div className="jp-col-lg-12 jp-m-t-14 jp-p-l-16 jp-text-center">
                                            <span><a onClick={handlePageReload}> Reload</a></span>
                                            {/* <b>|</b>
                                        <span><a href="/"> Go Back</a></span> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalPopup>
                    </div>)
                    :

                    (<>{(!homepageData) ?
                        <SkeletonDesktop />

                        : <div>
                            <div className='jp-Page-MainWrap'>
                                {<div className='jp-page-wrap jp-landing-wrap '>
                                    {
                                        (homepageData && homepageData.banners !== undefined && (homepageData.banners.length > 0)) &&
                                        <div className='jp-banner-wrap'
                                            style={{
                                                backgroundImage: `url("${homepageData.banners[0].webimage}")`,
                                                // backgroundImage: `url("${'/img/BackgroundHome.png'}")`, 
                                                height: 'calc(75vh - 79px)',
                                                width: '100%',
                                                backgroundPosition: 'center right',
                                                backgroundRepeat: 'no-repeat'
                                            }}>
                                            <div className='BannerContainer jp-container jp-banner-content'>
                                                {/* with 300 million image */}
                                                {/* {(homepageData.banners !== undefined && (homepageData.banners.length > 0)) &&
                                                    <div className="jp-row jp-text-center" dangerouslySetInnerHTML={{ __html: props.pageType == 'international' ? '<h1 class="jp-color-white jp-font-40 jp-m-b-10" style="outline: 0px; margin-right: 0px; margin-bottom: 10px; margin-left: 0px; padding: 0px; border: 0px; vertical-align: baseline; font-family: Montserrat-regular; color: #4d4d4f; letter-spacing: 0.3px; font-size: 40px; line-height: 1.27; text-align: center;">Book your International Stay with Us and<span style="color:#ef4044; font-family: Montserrat-bold;"> Save</span><span style="color:#4d4d4f;font-family: Montserrat-bold;"> Miles on your Booking!</span></h1> <h2 class="jp-color-white jp-font-32 jp-font-regular" style="outline: 0px; margin-right: 0px; margin-bottom: 0px; margin-left: 0px; padding: 0px; border: 0px; vertical-align: baseline; font-family: Montserrat-regular; color: #4d4d4f; letter-spacing: 0.3px; font-size: 32px; text-align: center;">Now That\'s Privilege!</h2>' : "<div class='banner-new'>" + homepageData.banners[0].webtext + "<img src='./nextHotels/img/banner_logo_x.webp'></div>" }}></div>
                                                } */}
                                                {/* with 300 million image */}
                                                {(homepageData.banners !== undefined && (homepageData.banners.length > 0)) &&
                                                    <div className="jp-row jp-text-center" dangerouslySetInnerHTML={{ __html: props.pageType == 'international' ? '<h1 class="jp-color-white jp-font-40 jp-m-b-10" style="outline: 0px; margin-right: 0px; margin-bottom: 10px; margin-left: 0px; padding: 0px; border: 0px; vertical-align: baseline; font-family: Montserrat-regular; color: #4d4d4f; letter-spacing: 0.3px; font-size: 40px; line-height: 1.27; text-align: center;">Book your International Stay with Us and<span style="color:#ef4044; font-family: Montserrat-bold;"> Save</span><span style="color:#4d4d4f;font-family: Montserrat-bold;"> Miles on your Booking!</span></h1> <h2 class="jp-color-white jp-font-32 jp-font-regular" style="outline: 0px; margin-right: 0px; margin-bottom: 0px; margin-left: 0px; padding: 0px; border: 0px; vertical-align: baseline; font-family: Montserrat-regular; color: #4d4d4f; letter-spacing: 0.3px; font-size: 32px; text-align: center;">Now That\'s Privilege!</h2>' : "<div class='banner-new'>" + homepageData.banners[0].webtext + "</div>" }}></div>
                                                }
                                                <SearchWidget
                                                    loggedIn_Info={props.loggedIn_Info}
                                                    mop={mopType}
                                                />

                                                <div className='jp-row jp-banner-cards' style={{ paddingTop: '50px' }}>
                                                    {(homepageData.blocks !== undefined && (homepageData.blocks.length > 0)) ?
                                                        homepageData.blocks.map((block, id) => {
                                                            return (
                                                                <div key={id} className={`jp-col-lg-4 jp-text-center ${id !== homepageData.blocks.length - 1 ? "jp-side-line" : ""}`}>
                                                                    <div className='jp-banner-card'>
                                                                        <h4 className='jp-banner-card-title' dangerouslySetInnerHTML={{ __html: block.webtitle }}>
                                                                        </h4>
                                                                        <p className='jp-para-02' dangerouslySetInnerHTML={{ __html: block.webdesc }}>
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        // <ErrorComponent 
                                                        //     errCode="api-error"
                                                        //     errDesc="homepageData.blocks"
                                                        // />
                                                        ""
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div>
                                }


                                <OffersComponent
                                    //sectionTitle= {sectionTitles["offer_desktop"]}
                                    loggedIn_Info={props.loggedIn_Info}
                                    offers={{ offer: homepageData.offer }}
                                    sectionTitle={props.pageType == 'international' ? "International Hotel Booking Offers" : "Domestic Hotel Booking Offers"}
                                    pageType={props.pageType}
                                    css={css}
                                //homepageData={homepageData}
                                />

                                {config.oldDiscovery && <> {(!props.iscrawler) ?
                                    <LazyLoad height={1187}>
                                        <Discovery
                                            // discovery={discovery}
                                            sectionTitle={props.pageType == 'international' ? "Discover International Homestay & Weekend Getaways" : sectionTitles["discover_desktop"]}
                                            HotelDetailRedirect={HotelDetailRedirect}
                                            loggedIn_Info={props.loggedIn_Info}
                                            homepageData={props.homepageData}
                                            pageType={props.pageType}
                                            mop={mopType}
                                            css={css}
                                        />
                                    </LazyLoad>
                                    :
                                    <Discovery
                                        // discovery={discovery}
                                        sectionTitle={props.pageType == 'international' ? "Discover International Homestay & Weekend Getaways" : sectionTitles["discover_desktop"]}
                                        HotelDetailRedirect={HotelDetailRedirect}
                                        loggedIn_Info={props.loggedIn_Info}
                                        homepageData={props.homepageData}
                                        pageType={props.pageType}
                                        mop={mopType}
                                        css={css}
                                    />
                                }</>}

                                {/* Explore  */}
                                {config.newDiscovery && <>{(!props.iscrawler) ?
                                    <LazyLoad height={1187}>
                                        <Discovery_New
                                            // discovery={discovery}
                                            sectionTitle={sectionTitles["explore"]}
                                            HotelDetailRedirect={HotelDetailRedirect}
                                            loggedIn_Info={props.loggedIn_Info}
                                            homepageData={props.homepageData}
                                            pageType={props.pageType}
                                            mop={mopType}
                                            css={css}
                                        />
                                    </LazyLoad>
                                    :
                                    <Discovery_New
                                        // discovery={discovery}
                                        sectionTitle={sectionTitles["explore"]}
                                        HotelDetailRedirect={HotelDetailRedirect}
                                        loggedIn_Info={props.loggedIn_Info}
                                        homepageData={props.homepageData}
                                        pageType={props.pageType}
                                        mop={mopType}
                                        css={css}
                                    />
                                }</>}

                                {/* Explore  */}

                                {/* Apartments */}

                                {(!props.iscrawler) ?
                                    <LazyLoad height={1187}>
                                        <Apartments
                                            // apartments={apartments}
                                            HotelDetailRedirect={HotelDetailRedirect}
                                            loggedIn_Info={props.loggedIn_Info}
                                            sectionTitle={props.pageType == 'international' ? "Best Apartments in Abroad" : sectionTitles["apartments_more"]}
                                            // loggedIn_Info={props.loggedIn_Info}
                                            homepageData={props.homepageData}
                                            pageType={props.pageType}
                                            mop={mopType}
                                            css={css}
                                        />
                                    </LazyLoad>
                                    :
                                    <Apartments
                                        // apartments={apartments}
                                        HotelDetailRedirect={HotelDetailRedirect}
                                        loggedIn_Info={props.loggedIn_Info}
                                        sectionTitle={props.pageType == 'international' ? "Best Apartments in Abroad" : sectionTitles["apartments_more"]}
                                        // loggedIn_Info={props.loggedIn_Info}
                                        homepageData={props.homepageData}
                                        pageType={props.pageType}
                                        mop={mopType}
                                        css={css}
                                    />
                                }
                                {(!props.iscrawler) ?
                                    <LazyLoad height={1187}>
                                        {/* Destinations */}
                                        <Destinations
                                            HotelDetailRedirect={HotelDetailRedirect}
                                            sectionTitle={props.pageType == 'international' ? "Top Holiday Destinations in Worldwide" : sectionTitles["topdestinations"]}
                                            loggedIn_Info={props.loggedIn_Info}
                                            homepageData={props.homepageData}
                                            pageType={props.pageType}
                                            mop={mopType}
                                            css={css}
                                        />
                                    </LazyLoad>
                                    :
                                    <Destinations
                                        HotelDetailRedirect={HotelDetailRedirect}
                                        sectionTitle={props.pageType == 'international' ? "Top Holiday Destinations in Worldwide" : sectionTitles["topdestinations"]}
                                        loggedIn_Info={props.loggedIn_Info}
                                        homepageData={props.homepageData}
                                        pageType={props.pageType}
                                        mop={mopType}
                                        css={css}
                                    />
                                }

                                {(!props.iscrawler) ?
                                    <LazyLoad height={1187}>
                                        {!props.loggedIn_Info?.isLoggedIn &&
                                            <section className={["jp-save-more-section member-exclusive-deals", css["member-exclusive-deals"]].join(" ")}>
                                                <div className="jp-container">
                                                    <div className="jp-row">
                                                        <div className="jp-col-lg-1 jppl-hidden-sm"></div>
                                                        <div className="jp-col-lg-10 p-col-lg-12 jppl-col-sm-12 paddingarea">
                                                            <Advertising
                                                                loggedIn_Info={props.loggedIn_Info}
                                                                pageName="hotelsnew-home"
                                                                subCategory1="home"
                                                                milesCategory="None"
                                                            />
                                                        </div>
                                                        {/* <div className="jp-col-lg-1 jppl-hidden-sm"></div> */}
                                                    </div>
                                                </div>
                                            </section>
                                        }
                                    </LazyLoad>
                                    :
                                    !props.loggedIn_Info?.isLoggedIn &&
                                    <section className="jp-save-more-section member-exclusive-deals">
                                        <div className="jp-container">
                                            <div className="jp-row">
                                                <div className="jp-col-lg-1 jppl-hidden-sm"></div>
                                                <div className="jp-col-lg-10 p-col-lg-12 jppl-col-sm-12 paddingarea">
                                                    <Advertising
                                                        loggedIn_Info={props.loggedIn_Info}
                                                        pageName="hotelsnew-home"
                                                        subCategory1="home"
                                                        milesCategory="None"
                                                    />
                                                </div>
                                                {/* <div className="jp-col-lg-1 jppl-hidden-sm"></div> */}
                                            </div>
                                        </div>
                                    </section>
                                }

                                {/* Interbook Recommendations for you */}
                                <section className='jp-common-section mtb-48 ptb-0 weekend-travels-details'>
                                    <div className="jp-container">
                                        <div className="jp-row">
                                            <div className="jp-col-lg-1 jppl-hidden-sm"></div>
                                            <div className={['jp-col-lg-10 jppl-col-sm-12 recommandation weekend-gateway', css['dest-desktop-plr'], 'home-stay-friendly', css['same-head-h2']].join(" ")}>

                                                {/* {(!props.iscrawler) ? */}
                                                {/* <LazyLoad height={1187}> */}
                                                {/* <Recommendation   
                                                    HotelDetailRedirect={HotelDetailRedirect}
                                                    sectionTitle={props.pageType == 'international' ? "Staycation Friendly Hotels in Abroad" : sectionTitles["recommendations_plain"]}
                                                    loggedIn_Info={props.loggedIn_Info}
                                                    recommendationData={props.recommendationData}
                                                    homepageData={props.homepageData}
                                                    pageType= {props.pageType}
                                                //showRecommendationLoader={true}
                                                //showSpecialDealsLoader={true}
                                                /> */}
                                                {/* <CommonHotelRecommendationDesktop
                                                            HotelDetailRedirect={HotelDetailRedirect}
                                                            sectionTitle={props.pageType == 'international' ? "Staycation Friendly Hotels in Abroad" : sectionTitles["recommendations_plain"]}
                                                            loggedIn_Info={props.loggedIn_Info}
                                                            recommendOrSpecialDealData={props.recommendationData}
                                                            homepageData={props.homepageData}
                                                            pageType={props.pageType}
                                                            apiAction={1}
                                                            type={mopType}
                                                            css={css}
                                                        /> */}
                                                {/* </LazyLoad> */}
                                                {/* : */}
                                                {/* // <Recommendation
                                                    //     HotelDetailRedirect={HotelDetailRedirect}
                                                    //     sectionTitle={props.pageType == 'international' ? "Staycation Friendly Hotels in Abroad" : sectionTitles["recommendations_plain"]}
                                                    //     loggedIn_Info={props.loggedIn_Info}
                                                    //     recommendationData={props.recommendationData}
                                                    //     homepageData={props.homepageData}
                                                    //     pageType= {props.pageType}
                                                    // //showRecommendationLoader={true}
                                                    // //showSpecialDealsLoader={true}
                                                    // />
                                                    // <CommonHotelRecommendationDesktop
                                                    //     HotelDetailRedirect={HotelDetailRedirect}
                                                    //     sectionTitle={props.pageType == 'international' ? "Staycation Friendly Hotels in Abroad" : sectionTitles["recommendations_plain"]}
                                                    //     loggedIn_Info={props.loggedIn_Info}
                                                    //     recommendOrSpecialDealData={props.recommendationData}
                                                    //     homepageData={props.homepageData}
                                                    //     pageType={props.pageType}
                                                    //     apiAction={1}
                                                    //     type={mopType}
                                                    //     css={css}
                                                    // /> */}
                                                {/* // } */}

                                                {/* Weekend Gateway start */}
                                                {(!props.iscrawler) ?
                                                    <LazyLoad height={1187}>
                                                        {/* <SpecialDeals
                                                    HotelDetailRedirect={HotelDetailRedirect}
                                                    sectionTitle={props.pageType == 'international' ? "International Weekend Getaway & Travel Deals" : sectionTitles["specialdeals_plain"]}
                                                    loggedIn_Info={props.loggedIn_Info}
                                                    specialDeals={props.specialDeals}
                                                    homepageData={props.homepageData}
                                                    pageType= {props.pageType}
                                                //showRecommendationLoader={true}
                                                //showSpecialDealsLoader={true}
                                                /> */}
                                                        <CommonHotelRecommendationDesktop
                                                            HotelDetailRedirect={HotelDetailRedirect}
                                                            sectionTitle={props.pageType == 'international' ? "International Weekend Getaway & Travel Deals" : sectionTitles["specialdeals_plain"]}
                                                            loggedIn_Info={props.loggedIn_Info}
                                                            recommendOrSpecialDealData={props.specialDeals}
                                                            homepageData={props.homepageData}
                                                            pageType={props.pageType}
                                                            apiAction={2}
                                                            type={mopType}
                                                            css={css}
                                                        />
                                                    </LazyLoad>
                                                    :
                                                    // <SpecialDeals
                                                    //     HotelDetailRedirect={HotelDetailRedirect}
                                                    //     sectionTitle={props.pageType == 'international' ? "International Weekend Getaway & Travel Deals" : sectionTitles["specialdeals_plain"]}
                                                    //     loggedIn_Info={props.loggedIn_Info}
                                                    //     specialDeals={props.specialDeals}
                                                    //     homepageData={props.homepageData}
                                                    //     pageType= {props.pageType}
                                                    //     //showRecommendationLoader={true}
                                                    //     //showSpecialDealsLoader={true}
                                                    // />
                                                    <CommonHotelRecommendationDesktop
                                                        HotelDetailRedirect={HotelDetailRedirect}
                                                        sectionTitle={props.pageType == 'international' ? "International Weekend Getaway & Travel Deals" : sectionTitles["specialdeals_plain"]}
                                                        loggedIn_Info={props.loggedIn_Info}
                                                        recommendOrSpecialDealData={props.specialDeals}
                                                        homepageData={props.homepageData}
                                                        pageType={props.pageType}
                                                        apiAction={2}
                                                        type={mopType}
                                                        css={css}
                                                    />
                                                }{/* Weekend Gateway end */}
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                {(!props.iscrawler) ?
                                    <LazyLoad>
                                        {/* Why us FAQ */}
                                        <HomeFaq
                                            sectionTitle={sectionTitles["whyinterbook"]}
                                            homepageData={props.homepageData}
                                            css={css}
                                        />
                                    </LazyLoad>
                                    :
                                    <HomeFaq
                                        sectionTitle={sectionTitles["whyinterbook"]}
                                        homepageData={props.homepageData}
                                        css={css}
                                    />
                                }
                            </div>
                        </div>}

                        <div className="interMilesTheme parent">
                            <div className={css["footerLinks_section"]}>
                                <div>
                                    <div className={css["mainfootLink"]}>
                                        <div style={{ margin: '20px' }}>
                                            <h3>Hotels By Popular Countries</h3>
                                            <div className={css["seo-domasticroute"]}>
                                                <ul>
                                                    <li className={css["seo-detailshotel"]}><a href="https://www.intermiles.com/hotels/list-of-cities-in-india.html">Hotels In India</a></li>
                                                    <li className={css["seo-detailshotel"]}><a href="https://www.intermiles.com/international-hotels/list-of-international-cities.html">International Hotels</a></li>
                                                </ul>
                                            </div>
                                            <div className={css["footerlinkviewall"]}>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className={css["mainfootLink"]}>
                                        <div style={{ margin: '0px 20px 0px' }}>
                                            <h3>Hotels By Popular Cities In India</h3>
                                            <div className={css["seo-domasticroute"]}>
                                                <ul>
                                                    <li className={css["seo-detailshotel"]}><a href="https://www.intermiles.com/hotels/hotels-in-mumbai.html">Hotels In Mumbai</a></li>
                                                    <li className={css["seo-detailshotel"]}><a href="https://www.intermiles.com/hotels/hotels-in-goa.html">Hotels In Goa</a></li>
                                                    <li className={css["seo-detailshotel"]}><a href="https://www.intermiles.com/hotels/hotels-in-new-delhi.html">Hotels In New Delhi</a></li>
                                                    <li className={css["seo-detailshotel"]}><a href="https://www.intermiles.com/hotels/hotels-in-jaipur.html">Hotels In Jaipur</a></li>
                                                    <li className={css["seo-detailshotel"]}><a href="https://www.intermiles.com/hotels/hotels-in-udaipur.html">Hotels In Udaipur</a></li>
                                                    <li className={css["seo-detailshotel"]}><a href="https://www.intermiles.com/hotels/hotels-in-shimla.html">Hotels In Shimla</a></li>
                                                    <li className={css["seo-detailshotel"]}><a href="https://www.intermiles.com/hotels/hotels-in-manali.html">Hotels In Manali</a></li>
                                                    <li className={css["seo-detailshotel"]}><a href="https://www.intermiles.com/hotels/hotels-in-bangalore.html">Hotels In Bangalore</a></li>
                                                    <li className={css["seo-detailshotel"]}><a href="https://www.intermiles.com/hotels/hotels-in-mussoorie.html">Hotels In Mussoorie</a></li>
                                                    <li className={css["seo-detailshotel"]}><a href="https://www.intermiles.com/hotels/hotels-in-ooty.html">Hotels In Ooty</a></li>
                                                    <li className={css["seo-detailshotel"]}><a href="https://www.intermiles.com/hotels/hotels-in-hyderabad.html">Hotels In Hyderbad</a></li>
                                                    <li className={css["seo-detailshotel"]}><a href="https://www.intermiles.com/hotels/hotels-in-kolkata.html">Hotels In Kolkata</a></li>
                                                    <li className={css["seo-detailshotel"]}><a href="https://www.intermiles.com/hotels/hotels-in-chandigarh.html">Hotels In Chandigarh</a></li>
                                                    <li className={css["seo-detailshotel"]}><a href="https://www.intermiles.com/hotels/hotels-in-lucknow.html">Hotels In Lucknow</a></li>
                                                    <li className={css["seo-detailshotel"]}><a href="https://www.intermiles.com/hotels/hotels-in-pune.html">Hotels In Pune</a></li>
                                                    <li className={css["seo-detailshotel"]}><a href="https://www.intermiles.com/hotels/hotels-in-ahmedabad.html">Hotels In Ahmedabad</a></li>
                                                    <li className={css["seo-detailshotel"]}><a href="https://www.intermiles.com/hotels/hotels-in-chennai.html">Hotels In Chennai</a></li>
                                                    <li className={css["seo-detailshotel"]}><a href="https://www.intermiles.com/hotels/hotels-in-dehradun.html">Hotels In Dehradun</a></li>
                                                    <li className={css["seo-detailshotel"]}><a href="https://www.intermiles.com/hotels/hotels-in-varanasi.html">Hotels In Varanasi</a></li>
                                                    <li className={css["seo-detailshotel"]}><a href="https://www.intermiles.com/hotels/hotels-in-patna.html">Hotels In Patna</a></li>
                                                </ul>
                                            </div>
                                            <div className={css["footerlinkviewall"]}>
                                                <a href="https://www.intermiles.com/hotels/list-of-cities-in-india.html">View All</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className={css["mainfootLink"]}>
                                        <div className='footer-link-all' style={{ margin: '20px' }}>
                                            <h3>Hotels By Popular International Cities</h3>
                                            <div className={css["seo-domasticroute"]}>
                                                <ul>
                                                    <li className={css["seo-detailshotel"]}><a href="https://www.intermiles.com/international-hotels/indonesia/hotels-in-bali.html">Hotels In Bali</a></li>
                                                    <li className={css["seo-detailshotel"]}><a href="https://www.intermiles.com/international-hotels/thailand/hotels-in-bangkok.html">Hotels In Bangkok</a></li>
                                                    <li className={css["seo-detailshotel"]}><a href="https://www.intermiles.com/international-hotels/thailand/hotels-in-pattaya.html">Hotels In Pattaya</a></li>
                                                    <li className={css["seo-detailshotel"]}><a href="https://www.intermiles.com/international-hotels/usa/hotels-in-new-york.html">Hotels In New York</a></li>
                                                    <li className={css["seo-detailshotel"]}><a href="https://www.intermiles.com/international-hotels/united-kingdom/hotels-in-london.html">Hotels In London</a></li>
                                                    <li className={css["seo-detailshotel"]}><a href="https://www.intermiles.com/international-hotels/united-arab-emirates/hotels-in-dubai.html">Hotels In Dubai</a></li>
                                                    <li className={css["seo-detailshotel"]}><a href="https://www.intermiles.com/international-hotels/france/hotels-in-paris.html">Hotels In Paris</a></li>
                                                    <li className={css["seo-detailshotel"]}><a href="https://www.intermiles.com/international-hotels/nepal/hotels-in-kathmandu.html">Hotels In Kathmandu</a></li>
                                                    <li className={css["seo-detailshotel"]}><a href="https://www.intermiles.com/international-hotels/usa/hotels-in-las-vegas.html">Hotels In Las Vegas</a></li>
                                                    <li className={css["seo-detailshotel"]}><a href="https://www.intermiles.com/international-hotels/malaysia/hotels-in-kuala-lumpur.html">Hotels In Kaula Lampur</a></li>
                                                </ul>
                                            </div>
                                            <div className="footerlinkviewall"><a href="https://www.intermiles.com/international-hotels/list-of-international-cities.html">View All</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>)
                }
            </div>
        </ThemeProvider>
    )
}