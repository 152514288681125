import axios from "axios";
import url from "../config/config_backend.json";
import { addMinutes, format, parse } from "date-fns";
import { loginToggleModal } from "../services/newAuth"



const commonLayer = {
  isEmpty: function (obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  },

  getSeoData: function (data) {
    return new Promise((resolve, reject) => {
      axios
        .get(url.GetSeoData + "?pagename=" + data, {
          Params: {
            pagename: data,
          },
        })
        .then((res) => {
          return resolve(res.data);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
  CallAdobe: function (event) {
    // console.log("Call Adobe event hit here=====>>", event);
    if (window && window._satellite) {
      window._satellite.track(event);
    }
  },
  pageInfo: function (pageName, subCategory, props, searchType) {
    window.digitalData['pageInfo'] = {
      pageName: pageName,
      category: 'hotels new',
      subCategory1: subCategory,
      subCategory2: '',
      searchType: searchType,
      partner: 'JP',
      currency: 'INR',
      flowName: "",
      isWebView: 'N',
      pageSource: props.isMobile ? 'immob' : 'imweb',
      platform: props.isMobile ? 'immob' : 'imweb'
    }

  },
  enrollNow: function () {
    window.open(url.EnrollUrl);
  },
  loginnow: function () {
    if (url.isKeyCloakOn) {
      loginToggleModal()
    } else {
      window.loginToggleModal();
    }
  },
  Price: function (data) {
    return parseInt(data)
      .toFixed()
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  },

  addMinute: function (date) {
    let date1 = date;
    let time = addMinutes(parse(date1, "dd-MM-yyyy p", new Date()), 1);
    return format(time, "dd-MM-yyyy p");
  },
  monthFormate: function (date) {
    let date1 = date;
    let time = parse(date1.trim(), "dd-MM-yyyy", new Date());
    return format(time, "dd MMM yyyy");

  },
  CheckoutMonth: function (date) {
    let date1 = date;
    return format(new Date(date1), "dd-MMM-yyyy");
  },
  paxInfo: function (data) {
    let adults = 0;
    let children = 0;
    let roomsPeopleInfo = [];
    if (data && Array.isArray(data)) {
      data.forEach((room) => {
        let splitpeople = room ? room.split("-") : "";
        adults += parseInt(splitpeople[0][0]);
        children += parseInt(splitpeople[1][0]);

        let ages = [];
        var childSplit = splitpeople[2];
        if (childSplit !== "" && splitpeople[2] !== undefined) {
          childSplit = splitpeople[2].split(",");
          for (const element of childSplit) {
            let age = { age: parseInt(element) };
            ages.push(age);
          }
        }

        roomsPeopleInfo.push({
          adultDecrement: splitpeople[0][2],
          adultIncrement: splitpeople[0][3],
          adults: parseInt(splitpeople[0][0]),
          children: splitpeople[1][0],
          childDecrement: splitpeople[1][2],
          childIncrement: splitpeople[1][3],
          childAge: ages,
          disable: splitpeople[0][4],
        });
      });
    }

    return roomsPeopleInfo;
  },
  CallCT: function (event_name) {
    if (!window.clevertapData["Global"]["Page Name"]) window.clevertapData["Global"]["Page Name"] = window.globalCtData ? window.globalCtData["Page Name"] : (window.location.host + ((window.location.pathname == "/") ? "" : window.location.pathname)).replace(/\//g, ":");
    if (!window.clevertapData["Global"]["Login Status"]) window.clevertapData["Global"]["Login Status"] = window.globalCtData ? window.globalCtData["Login Status"] : 'Anonymous';
    if (!window.clevertapData["Global"]["Category"]) window.clevertapData["Global"]["Category"] = window.globalCtData ? window.globalCtData["Category"] : 'None'
    var clevertap_data = {
      ...window.clevertapData[event_name],
      ...window.clevertapData["Global"],
    };
    for (const key in clevertap_data) {
      if (clevertap_data[key] == null || clevertap_data[key] == undefined || (typeof (clevertap_data[key]) === 'string' && clevertap_data[key].trim().length == 0)) {
        delete clevertap_data[key]
      }
    }

    if ((document.referrer.length > 0 || window.location.pathname == '/') && window.clevertap) {
      window.clevertap.event.push(event_name, clevertap_data);
      // console.log(new Date().getTime(),' CleverTapEvent - '+event_name+' Pushed: ', JSON.stringify(clevertap_data));
    }
  },
  CallCTProfile: function (data) {
    window.clevertap.profile.push({
      Site: data,
    });
    // console.log("clevertap prof:", data);
  },
  CTError: function (message) {
    window.clevertapData["Global"]["Channel"] = globalCtData.Channel
    window.clevertapData["Global"]["CleverTap ID"] = window.clevertap?.getCleverTapID();
    window.clevertapData["Error"] = {
      "Action": "Error",
      "Error Scenario": "Hotel Details",
      "Error Message": typeof (message) == 'object' ? 'Oops! Something went wrong' : message
    }
    this.CallCT('Error')
  },
  detectCrawler: function (data) {
    var botPattern = "(googlebot\/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|FeedFetcher-Google|Google-Read-Aloud|DuplexWeb-Google|googleweblight|Storebot-Google|APIs-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)";
    var re = new RegExp(botPattern, 'i');
    var userAgent = data;
    var result = false;
    if (re.test(userAgent)) {
      result = true;
    }
    return result;
  }

};

export default commonLayer;
